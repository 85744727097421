import React from 'react';
import PropTypes from 'prop-types';

const calculateTimeLeft = (target) => {
  let difference = +new Date(target) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      j: Math.floor(difference / (1000 * 60 * 60 * 24)),
      h: Math.floor((difference / (1000 * 60 * 60)) % 24),
      m: Math.floor((difference / 1000 / 60) % 60),
      s: Math.floor((difference / 1000) % 60)
    };
  }

  return timeLeft;

}

const Countdown = ({date}) => {
  const [currentDate, setCurrentDate] = React.useState(date ? new Date(date) : new Date());
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft(date));

  React.useEffect(() => {
    const timer=setTimeout(() => {
      setTimeLeft(calculateTimeLeft(date));
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      timerComponents.push(
        <span>{"00"}{interval}{" "}</span>
        );
    } else {
      timerComponents.push(
        <span>
          {timeLeft[interval] < 10 ? "0" : ""}{timeLeft[interval]}{interval}{" "}
      </span>
      );

    }

  });

  return (
    <div className="countdown">
      <span className="timer">{timerComponents.length ? timerComponents : <span>Time's up!</span>}</span>
    </div>
  )
}

Countdown.propTypes = {
  date: PropTypes.string,
}

Countdown.defaultProps = {
  date: "2021-05-16T20:00:00Z",
}

export default Countdown

import PropTypes from 'prop-types'
import React from 'react'

const QuantityRange = ({ value, choices, onChange }) => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const pos = choices.indexOf(value);
    if(pos !== -1)(
      setIndex(pos)
    )
  }, [choices, value])

  const update = React.useCallback((sens) => {
    let newIndex = null;
    if(sens < 0 && index !== 0) {
      newIndex = index - 1;
    }
    if(sens > 0 && index < choices.length - 1 ){
      newIndex = index + 1;
    }


    if(newIndex !== null && onChange) {
      onChange(choices[newIndex]);
    }
  }, [index, choices, onChange]);
  return (
    <div className="quantity-range">
      <span className="decrease" onClick={() => update(-1)}>-</span>
      <span className="value">{choices[index]}</span>
      <span className="increase" onClick={() => update(1)}>+</span>
    </div>
  )
}

QuantityRange.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  choices: PropTypes.array.isRequired,
  onChange: PropTypes.func
}

QuantityRange.defaultProps = {
  initialValue: null,
  onChange: null,
}

export default QuantityRange

import React from 'react';


const Gallery = () => {
  const [currentImage, setCurrentImage] = React.useState(1);

  return (
    <>
      <div className="images-container">
        <img src="../Jacques_1.png" className={`gallery__image${currentImage === 1 ? ` active` : ''}`} id="jacques_2"/>
        <img src="../Jacques_2.png" className={`gallery__image${currentImage === 2 ? ` active` : ''}`} id="jacques_3" />
        <img src="../grosplanTshirt.jpeg" className={`gallery__image${currentImage === 3 ? ` active` : ''}`} id="jacques_4" />
      </div>
      <div className="switch-image">
      <span className={`switch${currentImage === 1 ? ` active` : ''}`} onClick={() => setCurrentImage(1)}></span>
    <span className={`switch${currentImage === 2 ? ` active` : ''}`} onClick={() => setCurrentImage(2)}></span>
        <span className={`switch${currentImage === 3 ? ` active` : ''}`} onClick={() => setCurrentImage(3)}></span>
    </div>
      </>
  )
}

export default Gallery

import React, { useState } from 'react'
import getStripe from '../utils/stripejs'
import PropTypes from 'prop-types'

const DELIVERY = process.env.GATSBY_DELIVERY
const DEFAULT_SKU = process.env.GATSBY_PRODUCT_XS;
const GET_SKU = {
  "XS": DEFAULT_SKU,
  "S": process.env.GATSBY_PRODUCT_S,
  "M": process.env.GATSBY_PRODUCT_M,
  "L": process.env.GATSBY_PRODUCT_L,
  "XL": process.env.GATSBY_PRODUCT_XL
}


const Checkout = ({quantity, size}) => {
  const [loading, setLoading] = useState(false)

  const redirectToCheckout = async event => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'AddToCart',   {
          value: 35 * quantity,
          currency: 'EUR',
          content_type: 'product', // required property
          content_ids: 'txpg522i2z' // required property, if not using 'contents' property
        });
      }
    }
    event.preventDefault()
    setLoading(true)

    const stripe = await getStripe();
    if(global.amplitude) {
      global.amplitude.logEvent('checkout');
    }
    const { error } = await stripe.redirectToCheckout({
      mode: 'payment',
      billingAddressCollection: 'required',
      shippingAddressCollection: {
        allowedCountries: ['FR']
      },
      lineItems: [{ price: GET_SKU[size] || DEFAULT_SKU, quantity: quantity }, { price: DELIVERY, quantity: 1 }],
      successUrl: `${window.location.origin}/success/?session_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${window.location.origin}`
    })

    if (error) {
      console.warn('Error:', error)
      setLoading(false)
    }
  }

  return (
    <button
      disabled={loading}
      /*style={
        loading ? { ...buttonStyles, ...buttonDisabledStyles } : buttonStyles
      }*/
      className="button is-primary"
      onClick={redirectToCheckout}
    >
      Je le précommande !
    </button>
  )
}

Checkout.propTypes = {
  quantity: PropTypes.number,
  size: PropTypes.string,
}

Checkout.defaultProps = {
  quantity: 1,
  size: "XS"
}

export default Checkout

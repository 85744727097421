import React  from 'react'
import QuantityRange from './quantityRange'
import Checkout from './checkout'
import Countdown from './countdown'
import Gallery from './gallery'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'


const ShopBanner = ({displayMore}) => {
  const [quantity, setQuantity] = React.useState(1);
  const [size, setSize] = React.useState("M");

  return (
    <section className="section">
      <div className="columns">
        <div className="column gallery">
          <Gallery />
        </div>
        <div className="column shop">
          <div>
            <span class="shop__countdown">Il ne reste plus longtemps pour commander</span>
            <Countdown />
          </div>
          <div className="shop__title">
            Le sublime t-shirt Jacques cherche ses nouveaux propriétaires !
          </div>
          <div className="shop__price">
            <span>35€</span><span className="mention">50% des bénéfices reversés à l'Institut Curie !</span>
          </div>
          <div className="shop__info">
            <div className="shop__card">
              <div className="shop__label">Engagé</div>
              <div className="shop_data">100%</div>
            </div>
            <div className="shop__card">
              <div className="shop__label">Coton bio</div>
              <div className="shop_data">100%</div>
            </div>
            <div className="shop__card">
              <div className="shop__label">Fun</div>
              <div className="shop_data">100%</div>
            </div>
            { displayMore && (
              <div className="shop__card">
                <div className="shop_data"><Link to="/shop/">+ de détails</Link></div>
              </div>
            )
            }

          </div>
          <div className="cta-group">
            <QuantityRange value={quantity} choices={[1,2,3,4,5,6,7,8,9,10]} onChange={(value) => setQuantity(value)}/>
            <QuantityRange value={size} choices={["XS", "S", "M", "L", "XL"]} onChange={(value) => setSize(value)}/>
            <Checkout quantity={quantity} size={size}/>
          </div>
          <div className="info-delivery">
            <img src="../Avion.png" alt="plane"/>
            <span>Livraison à partir du  15/06/21 :)</span>
          </div>
        </div>
      </div>
    </section>
  )
}

ShopBanner.propTypes = {
  displayMore: PropTypes.bool,
}

ShopBanner.defaultProps = {
  displayMore: true,
}

export default ShopBanner
